import dayjs from 'dayjs'
import Link from 'next/link'
import cx from 'classnames'

import Container from './Container'
import Logo from './Logo'
import { useContext } from 'react'
import { SettingsContext } from '../context/settings'
import Script from 'next/script'

function Footer() {
  const settings = useContext(SettingsContext)
  const footerMenuItems = settings?.menus.footer
  const legalMenuItems = settings?.menus.legal

  return (
    <footer className="footer">
      <Container>
        <Logo color="white" />
        {footerMenuItems?.length && (
          <div className="footer-menu">
            {footerMenuItems
              ?.filter((e) => e.title !== 'Contact')
              .map((item) => (
                <Link href={item.url} key={item.title}>
                  <a target={item.target} className="footer-menu-item">
                    {item.title}
                  </a>
                </Link>
              ))}
          </div>
        )}

        <div className="footer-copyright">
          <span className="footer-copyright-item">
            Copyright © {dayjs().format('YYYY')}
          </span>
          {legalMenuItems?.map((item) => (
            <Link href={item.url} key={item.title}>
              <a
                target={item.target}
                className={cx('footer-copyright-item', 'footer-copyright-link')}
              >
                {item.title}
              </a>
            </Link>
          ))}
        </div>
        {process.env.NEXT_PUBLIC_APP_ENV === 'production' && (
          <>
            <Script
              dangerouslySetInnerHTML={{
                __html: `_linkedin_partner_id = "2910748";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
              }}
            />
            <Script
              dangerouslySetInnerHTML={{
                __html: `(function(l) {
                if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                window.lintrk.q=[]}
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);})(window.lintrk);`,
              }}
            />
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=2910748&fmt=gif"
            />
          </>
        )}
      </Container>
      <style jsx>{`
        .footer {
          @apply bg-black pt-[100px] pb-[40px] text-white;
        }
        .footer-menu {
          @apply mt-[30px] grid grid-cols-2 items-center justify-center gap-y-4 text-center sm:flex;
        }
        .footer-menu-item {
          @apply px-4 font-ibm text-[15px] font-medium leading-[19.5px] tracking-[0.3px];
        }
        .footer-copyright {
          @apply mt-[100px] flex flex-col items-center justify-center gap-y-2 sm:flex-row;
          @apply font-inter text-[10px] font-normal leading-[22px];
        }

        .footer-copyright-item {
          font-size: 12px;
          line-height: 22px;
        }

        .footer-copyright-item + .footer-copyright-item {
          @apply sm:ml-[20px];
        }
        .footer-copyright-link {
          @apply underline;
        }
      `}</style>
    </footer>
  )
}

export default Footer
